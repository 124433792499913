import { createActions } from 'redux-actions';

const {
    getNews,
    getNewsSuccess,
    getNewsFailure,
    getNewsIdle,
}: {
    getNews?: any;
    getNewsSuccess?: any;
    getNewsFailure?: any;
    getNewsIdle?: any;
} = createActions(
    'GET_NEWS',
    'GET_NEWS_SUCCESS',
    'GET_NEWS_FAILURE',
    'GET_NEWS_IDLE'
);

export { getNews, getNewsSuccess, getNewsFailure, getNewsIdle };
