import { createActions } from 'redux-actions';

const {
    getNewsOne,
    getNewsOneSuccess,
    getNewsOneFailure,
    getNewsOneIdle,
}: {
    getNewsOne?: any;
    getNewsOneSuccess?: any;
    getNewsOneFailure?: any;
    getNewsOneIdle?: any;
} = createActions(
    'GET_NEWS_ONE',
    'GET_NEWS_ONE_SUCCESS',
    'GET_NEWS_ONE_FAILURE',
    'GET_NEWS_ONE_IDLE'
);

export { getNewsOne, getNewsOneSuccess, getNewsOneFailure, getNewsOneIdle };
