import { createActions } from 'redux-actions';

const {
    getInstruction,
    getInstructionSuccess,
    getInstructionFailure,
    getInstructionIdle,
}: {
    getInstruction?: any;
    getInstructionSuccess?: any;
    getInstructionFailure?: any;
    getInstructionIdle?: any;
} = createActions(
    'GET_INSTRUCTION',
    'GET_INSTRUCTION_SUCCESS',
    'GET_INSTRUCTION_FAILURE',
    'GET_INSTRUCTION_IDLE'
);

export {
    getInstruction,
    getInstructionSuccess,
    getInstructionFailure,
    getInstructionIdle,
};
